.bid-submission-wrapper {
  padding: 42px 2%;
}

.submission-wrap {
  background: linear-gradient(#081c52, #3a7e95);
  color: white;
  padding: 3px 30px;
  border-radius: 7px;
}

.payment-sucss {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 30px;
  margin-top: 30px;
}

.payment-sucss1 {
  display: flex;
  justify-content: space-between;
}

.sucess-wrap h3 {
  color: #3a7e95;
  font-size: 22px;
  font-family: sans-serif;
  text-transform: capitalize;
}

.sucess-wrap p {
  font-size: 18px;
  font-family: sans-serif;
  color: #28415b;
  font-weight: 500;
  width: 58%;
  line-height: 32px;
}

.tende-text {
  font-size: 18px;
  font-family: sans-serif;
  color: #28415b;
  font-weight: 500;
  text-transform: capitalize;
  text-decoration: none;
}

.payment-sucss1 div {
  width: 48%;
}

.imgwrap img {
  width: 75%;
}

.process-btn {
  text-align: end;
}

.process-btn button {
  padding: 10px 30px;
  background: #e8654e;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  text-transform: capitalize;
}

.back-button button {
  background: #f7f7f7;
  border: none;
  padding: 14px 20px;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
}

.back-button i {
  margin-right: 5px;
}

.back_wrapper_box {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #b1b1b1;
  padding-bottom: 20px;
}

.view-docs-wraper {
  display: flex;
  align-items: center;
}

.view-document {
  display: flex;
  align-items: center;
  background: #102b5d;
  height: 28px;
  color: white;
  border-radius: 3px;
  padding: 0px 10px;
  margin-left: 10px;
}

.view-document i {
  margin-right: 8px;
}

.msg-box i {
  background: #102b5d;
  padding: 8px;
  border-radius: 3px;
  color: white;
  font-size: 12px;
}


@media only screen and (max-width: 767px) {
  .payment-sucss {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 30px;
    margin-top: 30px;
  }

  .payment-sucss1 {
    display: block;
  }

  .payment-sucss1 div {
    width: 100%;
  }

  .sucess-wrap p {
    width: 100%;
  }
}
