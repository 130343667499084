.thumb-point {
  position: relative;
  height: 19px;
  line-height: 15px;
  width: 19px;
  font-size: 11px;
  text-align: center;
  font-weight: bold;
  border: 1px solid black;
  background-color: black; 
  border-radius: 50%;
  cursor: grab;
  bottom: 4px;
  left: 18px;
  z-index: 999999;
}

.tp-invert{
  left: 0;
}
.slider-invert{
  left: 18px;
}

.thumb-0 {
  background-color: #c3da58 !important;
}
.thumb-1 {
  background-color: #f0ea0e !important;
}
.thumb-2 {
  background-color: #f89659 !important;
}
.thumb-3 {
  background-color: #f85959 !important;
  visibility: hidden !important;
}
.thumb-4 {
  background-color: #f17151 !important;
}
