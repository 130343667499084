@import "../../../styles/style-variables.scss";

.toaster {
  .toaster-wrapper {
    position: fixed;
    z-index: 9999;
    top: 20px;
    width: 400px !important;
    min-height: 80px !important;
    font-weight: 600;
    color: white;
    min-height: 2.5rem;
    display: flex;
    align-items: center;
    right: 10px !important;
    animation-name: toaster-animation;
    animation-duration: 1s;
    padding: 10px 20px;
    border-radius: 5px;
  }

  .toaster-success {
    background-color: $green;
  }

  .toaster-error {
    background-color: $red;
  }

  .toaster-waning {
    background-color: #ffc107;
  }

  .toaster-none {
    background-color: $gray4;
  }

  @keyframes toaster-animation {
    0% {
      opacity: 20%;
      top: 0px;
    }
    50% {
      top: 10px;
    }
  }
}
