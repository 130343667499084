.Terms{
    margin-left:30px;
    margin-right:30px;
    text-align: justify;
}
.outside{
    list-style-position: outside;
}
.a{
    list-style-type: lower-alpha;
}
.b{
    list-style-type: lower-roman;
}
.c{
    list-style-type:lower-latin;
}