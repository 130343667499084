
.vendor-tender-detail {

    .status-badge-lg{
        color: white;
        text-align: center;
        border-radius: 2px;
        padding: 0.2rem;
        height: 0.2rem;
    }
    .view_docs{
        display: flex;
        justify-content: space-between;
    }
    .ls {
        letter-spacing: 2px;
    }
    hr {
        border-color: rgb(43, 69, 92);
    }

    .right {

        right: 12px;
    }

    .mr-2{
        margin-right: 1rem;
    }
    .pl-sm-0{
        @media (min-width:576px) {
            padding-left: 0;
        }
    }
}

table {
    border-collapse: collapse;
}