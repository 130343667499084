$font-size-34px: 2.125rem;
$font-size-21px: 1.313rem;
$font-size-13px: 0.85rem;
$font-size-16px: 1rem;
$font-size-14px: 0.875rem;

@font-face {
  font-family: "Alieron-bold";
  src: url("../assets//fonts/aileron/Aileron-Bold.otf");
}
@font-face {
  font-family: "Alieron-Light";
  src: url("../assets/fonts/aileron/Aileron-Regular.otf");
}

@mixin header() {
  font-family: "Alieron-bold", sans-serif;
}
@mixin paragraph() {
  font-family: "Alieron-Light", sans-serif;
}
@mixin media-max-width($width) {
  @media (max-width: $width) {
    @content;
  }
}

.header_title_head {
  @include header();
  font-size: $font-size-34px;
}
.header_title_paragraph {
  @include paragraph();
  font-size: $font-size-21px;
}
.header_title_caption {
  @include paragraph();
  font-size: $font-size-16px;
}
// for card opportinity and tender
.card_title_head {
  @include paragraph();
  font-size: $font-size-21px;
  font-weight: bold;
}
.card_title_subhead {
  @include paragraph();
  font-size: $font-size-16px;
  font-weight: bold;
}
.card_title_subhead_category {
  @include paragraph();
  font-size: $font-size-14px;
}
.card_title_content {
  @include paragraph();
  @include media-max-width(576px) {
    font-size: $font-size-13px;
  }
  font-size: $font-size-16px;
}
