@import "../../styles/style-variables.scss";
.vendor-directory{
    .no-record{
        width: 512px;
    }


    .a-logo{
        width: 120px;
    }

    .pr-2{
        padding-right: 20px;
    }

    .pl-2{
        padding-right: 20px;

    }

    .btn-outline{
        border: 1px solid $primary;
    }

    .btn-outline:hover{
        border: 1px solid $vendor-primary;
        color: $vendor-primary;
    }
}