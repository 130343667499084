@import "./style-variables.scss";

$obx-white: white;

.selected-nav {
  color: #041359 !important;
  font-weight: 600 !important;
}
body {
  font-family: "Nunito Sans", sans-serif;
}

.header-splitter {
  border-bottom: 1px solid rgba(78, 78, 78, 0.192);

  .user-circle {
    height: 3rem;
    width: 3rem;
    border-radius: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #d5e9e8;

    .user-name-new {
      color: #0b5f4e;
      font-size: 1rem;
      font-weight: 700;
    }
  }
}

.content-container {
  min-height: 70vh;
}

.obx-white {
  color: $obx-white !important;
}

.obx-blue {
  // background-color:  #041359 !important;
  background-image: linear-gradient(83deg, rgb(4, 21, 78), #3f889b);
  // background-image: linear-gradient(to right, rgb(7, 34, 122) , #ff3f6cb9);
  border: none;
}

.full-height {
  height: 100%;
}

.full-width {
  width: 100%;
}
.obx-rc {
  border-radius: 0.5rem;
}

.obx-small {
  font-size: 0.8rem;
}

.obx-banner {
  padding-right: 0 !important;
  height: 12rem;
  background: linear-gradient(90deg, rgb(6, 17, 97), rgb(41, 143, 241));
  // background-image: linear-gradient(to right, rgb(50, 74, 152) , #ff3f6c8f);
  // border: none;
}

.obx-card-border {
  border: 1px solid rgb(236, 229, 229) !important;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.15) !important;
}

.obx-text-dgray {
  color: rgb(77, 77, 77) !important;
}

.obx-btn-color {
  background-color: #041359 !important;
  border: none !important;
}

.footer {
  margin-top: auto;
}

.text-white {
  color: white;
}

.img-100 {
  width: 100px;
}

.img-120 {
  width: 120px;
}

.font-18 {
  font-size: 18px;
}

.font-24 {
  font-size: 24px;
}

.text-right {
  text-align: end;
}

.remove-text-decoration {
  text-decoration: none !important;
}

.h-120 {
  height: 120px !important;
}

.space-between-btn {
  margin-right: 20px;
}

.float-right {
  float: right;
}

.icon-10 {
  width: 10px;
}

.icon-20 {
  width: 20px;
}

.font-10 {
  font-size: 10px;
}

.font-20 {
  font-size: 20px;
}

.bold-text {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.text-link {
  cursor: pointer;
  color: #041359;
  text-decoration: none;
}

.text-link:hover {
  color: #212529;
}

.error-message {
  color: #ff3f6c;
  font-style: italic;
  font-weight: 500;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.pop-min-width {
  min-width: 150px;
}

.hide-mobile-view {
  display: block;
}

.hide-web-view {
  display: none;
}

@media screen and (max-width: 600px) {
  .hide-mobile-view {
    display: none !important;
  }

  .hide-web-view {
    display: block !important;
  }

  .profile {
    .flex-1 {
      flex: 0.5 !important;
    }
    .flex-2 {
      flex: 0.5 !important;
    }
  }
}

.invalid-input {
  border-top: 1px solid $invalid !important;
  border: 1px solid $invalid !important;
}

.obx-ls-t {
  letter-spacing: 0.6px;
  font-weight: 650 !important;
}

.obx-ls-st {
  letter-spacing: 0.5px;
  font-weight: 500 !important;
}

.btn-sm-in-w {
  min-width: 4rem;
}

.mh-left-panel {
  min-height: 70vh;
}

.mw-2 {
  width: 4.5rem;
}
