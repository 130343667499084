@import "../../styles/style-variables.scss";

.chat {
    
    .chat-list{
        min-height: 25rem;
        max-height: 15rem;
        overflow-y: auto;
    }

    .user-list{
        height: 31.5rem;
        overflow-y: auto;
        border-right: 1px solid rgb(226, 226, 226);
        

        .user-item-wrapper{
            // background-color: rgb(224, 222, 222);
            border-radius: 5px;
            cursor: pointer;

            .user-item{
                position: relative;
                padding: .5rem 1rem;
                .time{
                    position: absolute;
                    right: .5rem;
                    bottom: 0;
                    font-size: 9px;
                    color: rgb(150, 148, 148);
                }
            }
        }
    }

    .selected{
        background-color: #a1baff;
    }

    .send-button{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background-color: $primary;
        color: white;
    }

    .message-item-wrapper{
        padding-top: .625rem;
        display: flex;

        .message-item{
            background-color: rgb(223, 227, 248);
            padding: .5rem 1rem;
            position: relative;
            display: inline-block;
            max-width: 60%;
            min-width: 25%;
            .time{
                position: absolute;
                right: .5rem;
                bottom: 0;
                font-size: 9px;
                color: rgb(150, 148, 148);
        }

    }

    .message-item-self{
        background-color: rgb(223, 248, 229) !important;
    }
    }

    .r-reverse{
        flex-direction: row-reverse;
    }

    .mr-4{
        margin-right: 4rem;
    }

    .msg-input{
        height: 3.5rem;
    }

    ::-webkit-scrollbar {display:none;}
}