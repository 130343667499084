@import "../../../styles/style-variables.scss";

.tender-card {
    
    .card {

        background-color: "white";
        height: 6rem !important;
        border-radius: 0.2rem;
        padding: 1rem;
        color: $gray8;
        border-color: $gray1;
    }   

  

    .card:hover {
        cursor: pointer;
    }
}  

.titem:hover {
    cursor: pointer;
    background-color: rgb(226, 227, 228);
}



.text {

    font-size: 0.85rem;
}

.button-title {

    font-size: 0.8rem !important;
}

.tender-status-badge {

    position:absolute;
   
    color: white;
    padding: 0.1rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    border-radius: 3px;
    font-size: 0.7rem;
    top: -0.5rem;
    left: -0.1rem;
    min-width: 3rem;
    text-align: center;
    background-color: rgb(185, 183, 183);
}

.draft {
    // background-color: rgb(212, 172, 11);
    background-image: linear-gradient(to right, rgb(150, 122, 11), rgb(212, 172, 11));
}

.cancelled {

    //background-color: rgb(160, 14, 14);
    background-image: linear-gradient(to right,rgb(100, 17, 31), rgb(160, 14, 14));
}

.published {
    //background-color: rgb(7, 109, 17);
    background-image: linear-gradient(to right, rgb(7, 109, 17) , rgb(22, 185, 38));
}

.closed {
    // background-color: rgb(36, 0, 0);
    background-image: linear-gradient(to right, rgb(0, 0, 0) , rgb(60, 21, 21));
}

.suspended {

    // background-color: rgb(117, 117, 117);
    background-image: linear-gradient(to right, rgb(83, 82, 82), rgb(117, 117, 117));
}

.awarded {
    background-image: linear-gradient(to right,#841486, #f9750a);
}

.submitted {
    background-image: linear-gradient(to right,#02490e, #02ac35);
}

.submitted {
    background-image: linear-gradient(to right,#02490e, #02ac35);
}

.denied {
    background-image: linear-gradient(90deg,#212529,#6c757d);
}

.draft-border {
    border: 1px solid rgb(212, 172, 11) !important;
}

.cancelled-border {

    border: 1px solid rgb(160, 14, 14);
}

.published-border {
    border: 1px solid rgb(7, 109, 17) !important;
}

.susbended-border {

    border: 1px solid rgb(95, 94, 94);
}

.published-badge{
    position:absolute;
    background-color: rgb(7, 109, 17);
    color: white;
    padding: 0.1rem;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    border-radius: 3px;
    font-size: 0.7rem;
    top: 8px;
    right: 0;
    min-width: 3rem;
    text-align: center;
}


.active-bookmark {
    color: #f2426e;
}



.submission-count{
    font-size: 12px;
    position: absolute;
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    top:-1px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 0.1rem;
    background-image: linear-gradient(83deg, rgb(4, 21, 78), #3f889b);
    // background: linear-gradient(90deg, rgb(6, 17, 97), rgb(41, 143, 241)) !important;
}