

.site{
    padding:0 20px;
    padding-bottom:4rem;
    .banner-img{
        max-width: 30rem;
    }

    .container-1{
        background-color: rgb(3, 26, 70);
    }

    .mh-cell{
        min-height:80vh;
    }

    .sub-header-text{
        font-size: 1.5rem;
        color: rgb(55, 65, 81);
        font-weight: 600;
    }

    .icon-40{
        width: 6rem;
    }
}

@media screen and (max-width:600px) {
    .mh-cell{
        min-height:20rem !important;
    }

    .banner-img{
        width: 20rem;
    }

    .mpt{
        padding-top: 2rem;
    }
}