$footer-color : #051236;

.footer {
    padding:  20px;
    width:"100%";
    min-height: 15rem;
    background-color: $footer-color;

    font-size: 16px;

}