
.opportunities{
    .no-record{
        width: 512px;
    }

    .pr-2{
        padding-right: 20px;
    }

    .tender-type-tab  {
        border: 1px solid #dee2e6;
        padding: 5px;
        border-radius: 40px;
        cursor: pointer;

        .tab-active {
            background-color: #324a98;
            color: white;
            border-radius: 40px;
        }
    }
}