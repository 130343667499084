@import "../../styles/style-variables.scss";
.create-tender {
  .obx-btn {
    margin: auto;
    background-color: rgb(255, 255, 255);
    height: 3.3rem !important;
    font-size: 0.85rem;
    cursor: pointer;
    margin-bottom: 0.5rem;
    box-shadow: -1px 5px 20px 0px #e9ecef;
  }

  .obx-btn-selected {
    position: relative;
    // background:linear-gradient(90deg, rgb(6, 17, 97), rgb(41, 143, 241)) !important;
    // background-image: linear-gradient(83deg, rgb(4, 21, 78), #3f889b);
    background-image: linear-gradient(83deg, #3f889b, #3f889b);
    border: 1px solid #3f889b !important;
    color: white;
    margin-right: 20px;
    // border-top-right-radius: .5rem;
    // border-bottom-right-radius: .5rem;
  }

  .obx-header-btn-selected {
    position: relative;
    // background: linear-gradient(90deg, rgb(6, 17, 97), rgb(41, 143, 241)) !important;
    // background-image: linear-gradient(83deg, rgb(4, 21, 78), #3f889b);
    background-image: linear-gradient(83deg, rgb(4, 21, 78), rgb(4, 21, 78));
    color: white;
    // border-top-right-radius: 5rem;
    // border-bottom-right-radius: 5rem;
  }
  // .obx-btn-selected:before {
  //     content: "";
  //     width: 0px;
  //     height: 0px;
  //     border-style: solid;
  //     border-width: 0px 20px 20px 0px;
  //     border-color: transparent rgb(6, 17, 97) transparent transparent;
  //     position: absolute;
  //     right: 0px;
  //     top: 0;
  //     margin-left: -40px;
  //   }

  .obx-btn-selected:after {
    content: " ";
    display: block;
    position: absolute;
    right: -19px;
    top: 0;
    border-top: 26px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 22px solid #3f889b;
  }

  // .obx-btn-selected:after {
  //         content: " ";
  //         display: block;
  //         position: absolute;
  //         left: 0px;
  //         top: 0;
  //         border-top: 26px solid transparent;
  //         border-bottom: 26px solid transparent;
  //         border-left: 20px solid rgb(41, 143, 241);
  //       }

  .obx-bg-color-secondary {
    background-color: rgb(243, 246, 246) !important;
  }

  .sub-header {
    text-indent: 0rem;
  }

  .sub-header-bg {
    background-color: #ffffff !important;
  }

  .obx-radio-group {
    margin-left: 4rem !important;
    margin-top: 1rem !important;
    margin-bottom: 1rem;
  }

  .obx-bb {
    // border-bottom: 1px solid lightgray
    border-radius: 0.5rem;
  }

  .obx-date-picker-btn {
    background-color: #f1f2f3;
    color: #353535;
    font-size: 0.9rem;
    width: 12rem;
    height: 2.4rem;
    border: 1px solid gray !important;
    border-radius: 0.4rem;
  }

  .cross-icon {
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px 20px;
    background-color: rgba(243, 21, 21, 0.158);
    color: red;
    border-bottom-left-radius: 10px;
    cursor: pointer;
  }

  .input-md {
    width: 5rem;
  }

  table {
    table-layout: fixed;

    thead {
      font-size: 0.9rem;

      tr {
        height: 4rem;
      }
    }

    tbody {
      tr {
        vertical-align: middle;
        height: 4rem;
      }
    }

    tbody.hover {
      tr {
        vertical-align: middle;
        height: 4rem;
      }

      tr:hover {
        background-color: #c4c5c7;
        cursor: pointer;
      }

      .td-selected {
        background-color: #c4c5d7;
        cursor: pointer;
        padding: 50px;
      }
    }
  }

  .obx-bb:hover {
    background-color: rgba(51, 50, 50, 0.233);
  }

  // .status-badge-lg{
  //     color: white;
  //     text-align: center;
  //     border-bottom-left-radius: 1.5rem;
  //     border-bottom-right-radius: 1.5rem;
  //     font-weight: 600;
  // }
  .status-badge-lg {
    color: white;
    text-align: center;
    border-bottom-left-radius: 1.5rem;
    border-bottom-right-radius: 1.5rem;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    font-weight: 600;
  }

  .date-wrapper {
    display: inline-block;
    border-radius: 7px;
  }

  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;

    &:hover {
      color: red;
    }
  }

  .profile-input {
    display: grid;
    place-items: center;
    cursor: pointer;
    width: 5rem;
    height: 5rem;
    background-color: rgb(221, 219, 221);
    border-radius: 10px;
    .add-icon {
      font-size: 2rem;
      color: rgb(119, 119, 119);
    }
  }

  .profile-div {
    overflow: hidden;
    cursor: pointer;
    width: 130px;
    height: 130px;
    background-color: rgb(221, 219, 221);
    border-radius: 10px;
    border: 1px solid rgb(196, 193, 193);
    img {
      width: 150px;
      height: 150px;
    }

    .remove-profile {
      display: none;
    }
  }

  .profile-div:hover {
    img {
      opacity: 50%;
    }
    .remove-profile {
      padding-top: 5px;
      position: absolute;
      display: block;
      width: 120px;
      z-index: 999999;
    }
  }

  .profile-input:hover {
    background-color: rgb(233, 232, 233);
  }
  .add-icon:hover {
    color: rgb(125, 125, 125);
  }
}

.border-wrapper {
  border: 1px solid rgb(167, 167, 167);
  border-style: dashed;
  border-radius: 1rem;
}

.version-card-wrapper {
  display: inline-block;
  width: 8rem;
  height: 8rem;
  border: 1px solid rgba(128, 128, 128, 0.137);
  padding: 10px;
  cursor: pointer;
  border-radius: 0.5rem;

  .version-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .icon {
      font-size: 2rem;
      color: rgb(85, 84, 84);
    }
  }

  &:hover {
    border: 1px solid $primary;

    .version-card {
      .icon {
        color: $primary;
      }
    }
    border-radius: 0.3rem;
  }
}

.preview-iframe {
  width: 100%;
  height: 60rem;
  overflow-y: scroll;
}

.preview-iframe-1 {
  width: 100%;
  height: 38rem;
  overflow-y: scroll;
}

.mr-2 {
  margin-right: 1rem;
}

.doument-preview-wrapper {
  display: inline-block;
  width: 4rem;
  height: 4rem;
  border: 1px solid rgba(128, 128, 128, 0.137);
  padding: 10px;
  cursor: pointer;
  border-radius: 0.5rem;

  .doument-preview {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .icon {
      font-size: 1.5rem;
      color: rgb(85, 84, 84);
    }
  }

  &:hover {
    border: 1px solid $primary;

    .doument-preview {
      .icon {
        color: $primary;
      }
    }
    border-radius: 0.3rem;
  }
}
.pincode_verification {
  border: 2px solid red !important;
}

.kpi_colum_subheading_font-size {
  font-size: 13px !important;
}

.tables_header_rowborderbottom {
  border-bottom: 1px solid black !important;
}
.table_style {
  border: 1px solid black !important;
  border-radius: 15px !important;
}
.kpi_table_datasize {
  font-size: 13px !important;
}
.kpi_table_remuneration_kpi_description {
  font-size: 15px !important;
}
.all_document_upload_hide {
  display: none !important;
}
.all_recommneded_hide {
  visibility: hidden !important;
}



.btn_upload {
  background-color: #041359!important;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: flex;
  overflow: hidden;
  padding: 4px 28px;
  height: 40px;
  flex-direction: column;
  justify-content: center;
}

.btn_upload:hover,
.btn_upload:focus {
  background-color: #7ca9e6;
}

.yes {
  /* align-items: center; */
  display: flex;
  justify-content: space-evenly;
  margin-top: 38px!important;
  text-align: center;
}

.btn_upload input {
  cursor: pointer;
  // filter: alpha(opacity=1);
  // -moz-opacity: 0;
  visibility: hidden;
  position: absolute
}



.btn-rmv1,
.btn-rmv2,
.btn-rmv3,
.btn-rmv4,
.btn-rmv5 {
  display: none;
}
.obx-btn-color{
  padding: 4px 28px;
  height: 40px;
}
.rmv {
  cursor: pointer;
  color: #fff;
  border-radius: 30px;
  border: 1px solid #fff;
  display: inline-block;
  background: rgba(255, 0, 0, 1);
  margin: -5px -10px;
}

.rmv:hover {
  background: rgba(255, 0, 0, 0.5);
}

.datePicker{
  width: 100%;
}