.cc-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 2px solid #ddd;
    border-radius: 0.3rem;
    // padding: 10px;
    // border-radius: 5px;
  }
  
  .left-button {
    background-color: #ddd;;
    color:  rgb(107, 106, 106);
    width: 3rem;
    border: none;
    cursor: pointer;
    font-size: 1.9rem;
  }
  
  .right-button {
    background-color: #ddd;
    width: 3rem;
    color:  rgb(107, 106, 106);
    border: none;
    cursor: pointer;
    font-size: 1.9rem;
  }
  
  .center-input {
    // padding-top: 1.3rem;
    border: 1px solid #ddd;
    width: 100%;
    font-size: 16px;
    border: none;
    text-align: center;
    vertical-align: middle;
  }

  .center-input:focus {
   
    outline: none;
  }
  