.tender-item {

    background-color: #58C25F;
    margin-bottom: 1rem;
    height: 5rem;
    border-radius: 0.4rem;
    td {
        margin: 0 0.5rem 0.5rem 0;
        padding: 0.5rem;
        text-align: left;
        background-color: rgb(75, 17, 17);
        border: 1px solid transparentize( #58C25F, 0.5 );
    }


}

.tender-detail {

    .status-badge-lg{
        color: white;
        text-align: center;
        border-radius: 2px;
        padding: 0.2rem;
        height: 0.2rem;
    }
    .ls {
        letter-spacing: 2px;
    }
    hr {
        border-color: rgb(43, 69, 92);
    }

    .right {

        right: 12px;
    }


}

table {
    border-collapse: collapse;
}

.twenty-per {

    margin: 20%;
}

.datePicker{
    width: 100%;
  }

.obx-versions {

    position: relative;
    .version-card {

        height: 3rem;
        border-radius: 0.2rem;
        background-color: rgb(251, 251, 251);
        margin-bottom: 0.5rem;
        padding: 1rem;
        box-shadow: 0 .1rem 0.3rem rgba(0,0,0,.15)!important;

        // margin: auto;
        // padding: auto;
        // margin-top: auto;
        // vertical-align: middle;
    }

    .right {
        text-align: right;
    }

    &:hover {
        cursor: pointer;
    }

}