.kpi{
    table {
        border-collapse:separate;
        border-spacing:0 15px;
      }
      
      table td, th {
        padding: 20px 10px;
      }
      
      table tr {
          background-color: #eaeefa;
        }
      
      table th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #d9dff3;
        width: 8rem !important;
      }
      
      td:first-child,
      th:first-child {
        border-radius: 10px 0 0 10px;
        width: 3rem !important;

      }
      td:last-child,
      th:last-child {
        border-radius: 0 10px 10px 0;
      }
}

.close-icon{
    position: absolute;
    right: 10px;
    top:30px;
    cursor: pointer;

    &:hover{
        color: red;
    }

    
}