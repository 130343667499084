$tooltip-bg-color: #fce6bf;
$tooltip-border-color: #efb98b;
$header-color: #6aa9f6;
$selected-color: hsl(213, 89%, 78%);
$facility-selected-color: #e7664d;
$terms-keydates-color: pink;
$header-text-color: black;
$kpi-alternate-rowcolor: #d3d3d3;

.tendercreate_header-color {
  background: $header-color !important;
}

.tooltip-background-color {
  background-color: $tooltip-bg-color !important;
  border: 1px solid $tooltip-border-color !important;
}

.options-selected-color {
  background-color: $selected-color !important;
}
.selected-hover tr:hover {
  background-color: $selected-color !important;
}
.selected-facility-color {
  border: 2px $facility-selected-color solid !important;
}
.terms-duration-color {
  background-color: $terms-keydates-color !important;
}

.itembutton {
  text-align: inherit;
}
.itembutton button {
  border: 1px solid black !important;
  border-top-left-radius: 8px !important;
  border-top-right-radius: 8px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  box-shadow: none !important;
  color: black !important;
}
.kpi_selction_alternate-row-bg {
  background-color: $kpi-alternate-rowcolor;
}
.all-tables_header_only-bg {
  background: #20b2aa !important;
}
