.submit-bid {
  .status-badge-lg {
    color: white;
    text-align: center;
    border-radius: 2px;
    padding: 0.2rem;
    height: 0.2rem;
  }

  .custom-input-bid {
    width: 10rem;
  }

  .custom-file-input-bid {
    width: 20rem;
  }

  .ls {
    letter-spacing: 2px;
  }
  hr {
    border-color: rgb(43, 69, 92);
  }

  .right {
    right: 12px;
  }

  .mr-2 {
    margin-right: 1rem;
  }

  .remove-icon {
    font-size: 14px;
    color: red;
    position: absolute;
    right: -10px;
    top: -10px;
  }
}

.all_document_visibility-hide {
  visibility: hidden !important;
}
