

.vendor-home{
    
    .obx-vendor-card{
        min-height: 6rem;

        // background: radial-gradient(958px at 95.7% 95.4%, rgb(243, 152, 107) 0%, rgb(239, 113, 104) 90%);
        // background: linear-gradient(90deg, rgb(6, 17, 97), rgb(41, 143, 241)) !important;
        // color: white;
    letter-spacing: 0.04em;
    font-weight: 400;

        .title{
            font-size: 1.2rem;
        }

        .ic{
            font-size: 1.5rem;
        }
    }

}

.pad-r{
    padding-left: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 1.5rem;
    padding-right: 1rem;
}
.pad-l{
    padding-top: 0 !important;
    padding-bottom: 1.5rem;
    padding-right: 0 !important;
    padding-left: 1rem;
}
