.forgot-password {

    .regenerate{
        font-size: 12px;
        cursor: pointer;
    }

    .email-edit{
        position: absolute;
        top:16px;
        right:20px
    }
  }
