.toggle-bar-wrap input[type="checkbox"] {
  position: relative;
  width: 48px;
  height: 26px;
  appearance: none;
  background: grey;
  margin-left: 20px;
  outline: none;
  border-radius: 2rem;
  cursor: pointer;
  box-shadow: inset 0 0 5px rgb(0 0 0 / 50%);
  top: 6px;
}

.toggle-bar-wrap input[type="checkbox"]::before {
  content: "";
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 0px;
  left: 0;
  transition: 0.5s;
}

.toggle-bar-wrap input[type="checkbox"]:checked::before {
  transform: translateX(100%);
  background: #fff;
}

.toggle-bar-wrap input[type="checkbox"]:checked {
  background:#20b2aa;
}
