@import "../../../styles/style-variables.scss";

.title-header {
  height: 5rem;
  width: 100%;
  display: flex;
  align-items: center;
  // background: linear-gradient(90deg, rgb(6, 17, 97), rgb(41, 143, 241));
  
  .title{
    font-size: $font-size-36px;
    font-weight: 700;
    // color: white;
  }
}
