/* .file-list {
  list-style: none;
  padding: 0;
  margin: 0;
} */

.file-item {
  display: inline-block;
  margin: 10px;
  padding: 10px; /* Adjusted padding for better spacing */
  padding-bottom: 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  width: 150px; /* Adjusted width to fit the icon and text */
  text-align: center;
}

.pdf-link{
  display: block;
  width: 100%;
  text-decoration: none;
  padding: 10px 5px; /* Padding inside the link */
  overflow: hidden;
}

.pdf-link:hover {
  color: black;
}

.link-name {
  display: -webkit-box; /* Required for truncation */
  -webkit-line-clamp: 2; /* Limit text to 2 lines */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* Add ellipsis after overflow */
  font-size: 14px;
  width: 100%;
  margin: 10px 0;
  height: 45px; /* Adjust height for 2 lines */
}

.file-icon {
  font-size: 40px;
}

.delete-ic {
  position: absolute;
  font-size: 14px;
  background-color: red;
  color: white;
  width: 20px;
  height: 20px;
  right: 0;
  top: 0;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  /* border: 1px solid red; */
}