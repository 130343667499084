@import "../../../../styles//style-variables.scss";

.change-password {


.header-text {
  font-weight: 600;
  font-size: $font-size-24px;
}

  .password-icon {
    position: absolute;
    z-index: 9999;
    top: 3.3rem;
    right: 0.75rem;
    padding: 0 0.25rem;
    background: $white;
  }

  .app-input {
    border-radius: 5px;
    width: 100%;
    height: 3rem;
    border: none;
    font-size: $font-size-14px;
    padding: 1rem;
    background-color: white;
    border: 1px solid #d5d8dc;
    color: $app-black !important;
  }
  .app-input-label {
    font-weight: 500;
    font-size: $font-size-12px;
    line-height: 0.8rem;
    padding-bottom: 0.85rem;
    margin-bottom: 0 !important;
    color: $label-black;
    display: inline-block;
  }
  

  .app-input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b2aeab;
    opacity: 1; /* Firefox */
  }
  
  
  .app-input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #b2aeab;
  }


  .app-button-primary {
    border: none;
    color: white;
    background: $primary;
    border-radius: 0.25rem;
    font-size: 0.84rem;
    font-weight: bold;
    text-align: center;
    padding: 0.8rem 2.5rem;
    transition-duration: 0.4s;
    outline: none;
    cursor: pointer;
    word-break: keep-all;
  }

  .app-button-secondary {
    border: 1px solid $gray1;
    color: $black2;
    background-color: transparent;
    border-radius: 0.25rem;
    font-size: 0.84rem;
    font-weight: 600;
    text-align: center;
    padding: 0.8rem 2.5rem;
    transition-duration: 0.4s;
    outline: none;
    cursor: pointer;
    word-break: keep-all;
  }

  .app-button-primary:hover {
    opacity: 0.8;
  }
  .app-button-primary:focus {
    outline: none;
  }

  .app-button-secondary:hover {
    opacity: 0.8;
  }

  .app-button-secondary:focus {
    outline: none;
  }
  .app-button-disabled {
    background: $gray3 !important;
  }

  .mr-2{
    margin-right: 20px;
  }
  
  .bw-spaces-input {
    padding-top: 10px;
  }

.invalid-input-container {
  .app-input-label {
    font-weight: 600;
    color: $invalid;
  }
  .app-input {
    border: 1px solid $invalid;
  }

  .app-input-text-area {
    border: 1px solid $invalid !important;
  }
}
}

.change-password-custom-modal {
  z-index: 99999;
}
