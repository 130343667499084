
.active-bids{
    .no-record{
        width: 512px;
    }

    .pr-2{
        padding-right: 20px;
    }

    .bid-status-badge {

      // position:absolute;
     
      color: white;
      padding: 0.1rem;
      padding-left: 0.3rem;
      padding-right: 0.3rem;
      border-radius: 3px;
      font-size: 0.7rem;
      top: -0.5rem;
      left: 0rem;
      min-width: 3rem;
      text-align: center;
      background-color: rgb(185, 183, 183);
  }

    table {
        border-collapse:separate;
        border-spacing:0 15px;
      }
      
      table td, th {
        padding: 20px 10px;
      }
      
      table tr {
          background-color: #eaeefa;
        }
      
      table th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #d9dff3;
        width: 8rem !important;
      }
      
      td:first-child,
      th:first-child {
        border-radius: 10px 0 0 10px;
        width: 3rem !important;

      }
      td:last-child,
      th:last-child {
        border-radius: 0 10px 10px 0;
      }
}