body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  //   "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Nunito Sans", sans-serif !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
// #cookie-popup {
//   position: fixed;
//   z-index: 9999;
//   right: 30px;
//   bottom: 30px;
//   width: 100%;
//   padding: 20px 30px;
//   -webkit-box-sizing: border-box;
//   box-sizing: border-box;
//   background: rgba(0, 0, 0, 0.85);
//   color: #eee;
// }

// #cookie-popup p:first-child {
//   margin: 0;
//   color: #eee;
//   font-weight: 900;
//   color: #00adef;
// }

// #cookie-popup p:nth-child(2) {
//   font-size: 14px;
//   line-height: 1.4;
//   margin-bottom: 25px;
// }

// #cookie-popup .link {
//   color: #00adef;
//   text-decoration: none;
// }

// #cookie-popup button {
//   width: 100%;
//   font-size: 16px;
//   font-weight: 600;
//   font-family: "Montserrat";
//   padding: 8px 0;

//   color: #fff;
//   background-color: #00adef;
// }

// #cookie-popup button:hover {
//   background-color: #000;
//   color: #fff;
// }

@media only screen and (max-width: 450px) {
  #cookie-popup {
    position: fixed;
    z-index: 9999;
    right: 10px;
    bottom: 30px;
    margin: 0 auto;
    width: 95%;
    padding: 20px 30px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.85);
    color: #eee;
  }

  #cookie-popup p:first-child {
    margin: 0;
    color: #eee;
     font-size: 14px;
    line-height: 1.4;
    margin-bottom: 25px;
  }

 

  #cookie-popup .link {
    color: #00adef;
    text-decoration: none;
  }

  #cookie-popup button {
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    font-family: "Montserrat";
    padding: 8px 0;

    color: #fff;
    background-color: #00adef;
  }

  #cookie-popup button:hover {
    background-color: #000;
    color: #fff;
  }
}
