@import "../../../styles/style-variables.scss";

.app-confirm {
}
.processd-checkout {
  color: white !important;
  background-color: #47a947;
  border-radius: 6px;
}
.button-boxes {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 10px;
  padding-top: 10px;
}
.cancel-button {
  background: #dddd;
  border-radius: 6px;
  color: #414141;
      font-weight: 700;
    font-size: 18px;
}
.app-confirm-custom-modal {
  font-size: $font-size-12px;
  display: grid !important;
  align-items: center;
  height: 85vh;
  .modal-content {
    border-radius: $font-size-10px;
  }

  .icon-80 {
    width: 2.25rem;
  }

  .content-wrapper {
    background-color: $gray5;
    border-radius: 1rem;
    border: 1px solid $gray1;
  }
  .abs-img {
    position: absolute;
    margin-left: -25px;
    top: 40px;
  }
  .close-icn {
    position: absolute;
    right: $font-size-10px;
  }

  .header-text {
    font-size: $font-size-16px;
    font-weight: 700;
  }

  .modal-footer {
    padding: 0;
    display: block;
    font-weight: 600;
    .border-right {
      border-right: 1px;
    }
    .reject-text {
      color: $red;
    }
  }
}
