.award-tender-btn {
    background-color: red; /* Set the background color to red */
    color: white; /* Change text color to white for contrast */
    font-size: 1.2rem; /* Increase font size */
    padding: 0.75rem 1.5rem; /* Adjust padding for a larger button */
    border-radius: 0.25rem; /* Optional: add rounded corners */
  }
  
  .award-tender-btn:hover {
    background-color: darkred; /* Darker shade on hover for feedback */
  }

  .obx-header-btn-selected {
    position: relative;
    // background: linear-gradient(90deg, rgb(6, 17, 97), rgb(41, 143, 241)) !important;
    // background-image: linear-gradient(83deg, rgb(4, 21, 78), #3f889b);
    background-image: linear-gradient(83deg, rgb(4, 21, 78), rgb(4, 21, 78));
    color: white;
    // border-top-right-radius: 5rem;
    // border-bottom-right-radius: 5rem;
  }