.no-records {

    margin: 3rem;
    .title {

        color: rgb(143, 149, 149);
        font-size: 2rem;
        text-align: center;
    }

    .sub-title {

        color: rgb(143, 149, 149);
        font-size: 1rem;
        text-align: center;
    }

    .title-sm {

        color: rgb(143, 149, 149);
        font-size: 1rem;
        text-align: center;
    }

    .sub-title-sm {

        color: rgb(143, 149, 149);
        font-size: 0.8rem;
        text-align: center;
    }
}