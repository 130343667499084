.title{
    width: 100%;
    border-width: 1;
    justify-content: center;
text-align: center;
}

.small_image{
    width: 600px;
    height: 400px;
    object-fit: cover; /* or use 'contain' to fit the whole image within the box */
}