@import "../../../styles/style-variables.scss";

.LoginForm {
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 80vh;

    .login-card{
      min-width: 30rem;
      max-width: 32rem;
      width: -webkit-fill-available;
      box-sizing: border-box;
      // border-top: 10px solid #212529
    }

    .mh-container{
      min-height: 45rem;
    }

    .buyer-vendor-wrapper{
      background-color: rgba(233, 233, 233, 0.445);
      border-radius: 40px;
      padding: 5px;

      .switch-button{
        height: 2rem;
        border-radius: 40px;
        cursor: pointer;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }


    .icon-wrapper{
      height: 120px;
      width: 120px;
      border-radius: 50% !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 40px;
      border: 1px solid rgb(209, 208, 208);
      background-color: #fff;
      position: absolute;
      top: -60px;
      left: 0; 
      right: 0; 
      margin-left: auto; 
      margin-right: auto; 
    }

    .vendor-button{
      color: white;
      background-color: rgb(231, 102, 77);
      border: none;

    }

    .buyer-button{
      background-color: rgb(50, 74, 152);
      color: white;
      border: none;
      &:hover {
        color: #fff;
        &:before {
          width: 100%;
        }
      }
    }

    .vendor-button:hover{
      color: white;
      background-color: rgb(231, 102, 77);
      border: none;

    }

    .buyer-button:hover{
      background-color: rgb(50, 74, 152);
      color: white;
      border: none;
    }

    .login-image{
      width: 100%;
      max-width: 720px;
    }
    
  }
  
  @media (max-width: 576px) {
    .LoginForm {
      padding: 0 20px;
    }
    .login-card{
      max-width: 400px;
    }

  }
  


  .btn-success {
    --bs-btn-bg: rgb(50, 74, 152)!important;
    --bs-btn-border-color:rgb(50, 74, 152)!important;
    --bs-btn-color:#fff !important;
    --bs-btn-hover-color: #fff !important;
    --bs-btn-hover-bg: rgb(50, 74, 152) !important;
    --bs-btn-hover-border-color: rgb(50, 74, 152) !important;
    --bs-btn-focus-shadow-rgb: 217,164,6 !important;
    --bs-btn-active-color: #fff !important;
    --bs-btn-active-bg: rgb(50, 74, 152) !important;
    --bs-btn-active-border-color: rgb(50, 74, 152) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    --bs-btn-disabled-color: #fff !important;
    --bs-btn-disabled-bg: rgb(50, 74, 152) !important;
    --bs-btn-disabled-border-color: rgb(50, 74, 152)!important;
  }

  .btn-warning{
    --bs-btn-bg: rgb(231, 102, 77) !important;
    --bs-btn-border-color:rgb(231, 102, 77) !important;
    --bs-btn-color:#fff !important;
    --bs-btn-hover-color: #fff !important;
    --bs-btn-hover-bg: rgb(231, 102, 67)  !important;
    --bs-btn-hover-border-color: rgb(231, 102, 67)  !important;
    --bs-btn-focus-shadow-rgb: 217,164,6 !important;
    --bs-btn-active-color: #000 !important;
    --bs-btn-active-bg: rgb(231, 102, 87)  !important;
    --bs-btn-active-border-color: rgb(231, 102, 87)  !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
    --bs-btn-disabled-color: #000 !important;
    --bs-btn-disabled-bg: rgb(231, 102, 37)  !important;
    --bs-btn-disabled-border-color: rgb(231, 102, 37)  !important;
  }
